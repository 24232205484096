
* {
    /* margin: 0;
    padding: 0; */
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
.img-phone{
  width: 60%;
}
.css-f7t77y .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
  min-width: 120px !important;
}
.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  width: 110px !important;
}
.newmwwd{
  width: 200px !important;
}
.headingmain{
  font-size: 34px;
  color: black;
}
.textkk{
  color: #02ca95 !important;
}
.model-text{
  font-size: 14px;
}
  .background-green {
    position: absolute;
    top: 0;
    width: 100%;
    height: 20%;
    /* background-color: #009688; */
  }
  .Img-size{
    width: 30px;
    display: flex;
    align-items: start !important;
  }
  .Img-form{
display: flex;
flex-direction: column;
  }
  .main-container {
    position: relative;
    /* width: 1000px; */
    max-width: 100%;
    height: calc(100vh - 40px);
    background: #fff;
    display: flex;
    /* box-shadow: 0px 1px 1px  0 rgba(0,0,0,0.5), 0px 2px 5px 0 rgba(0,0,0,0.6); */
  }

  .main-container .left-container {
    position: relative;
    width: 20%;
    padding: 50px;
    flex: 20%;
    background: #fff;
  }
  .SellNowButton{
    width: 100%;
    border:none;
    background: #02ca95;
    color: #fff;
    padding: 19px 31px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    border-radius: 3px;
    display: inline-block;
    outline: none !important;

  }
.Searchdata{
  display: flex;
  justify-content: end;
  padding:10px 10px;
  position: relative;

}
.p-dropdown-label{
  padding: 10px;
  border: none;
  border-radius: 10px;
}
.IconSearch{
  color: rgba(0, 0, 0, .65);
position: absolute;
right: 40px;
top: 25px;
}

  .main-container .right-container {
    position: relative;
    width: 100% !important;
    height: 100%;
    flex: 80%;
    background: #f8fafa;
  }
.p-heading-text{
    cursor: pointer;
    font-style: normal;
}
  /* .main-container .right-container::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: url(https://camo.githubusercontent.com/854a93c27d64274c4f8f5a0b6ec36ee1d053cfcd934eac6c63bed9eaef9764bd/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67);
opacity: 0.5;
} */
.formtext{
  color: rgb(151, 150, 150);
  font-size: 16px;
  text-transform: uppercase;
}
  .header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #ededed;
    padding: 0 15px;
  }

  .user-img {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
  }

  .dp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .nav-icons {
    display: flex;
    justify-content: flex-end;
    padding-left: 110px;
  }

  .nav-icons li {
    backgroud-color: pink;
    list-style: none;
    display: flex;
    cursor: pointer;
    color: #51585c;
    margin-left: 22px;
    font-size: 18px;
  }

  .notif-box {
    position: relative;
    display: flex;
    width: 100%;
    height: 70px;
    background: #76daff;
    align-items: center;
    font-size: 0.8em;
    text-decoration: none;
  }

  .notif-box i {
    position: relative;
    left: 13px;
    background: #fff;
    padding: 10px;
    width: 42px;
    height: auto;
    font-size: 20px;
    border-radius: 55%;
    cursor: pointer;
    color: #76daff;
  }

  .notif-box .fa-xmark {
    position: absolute;
    left: 260px;
    text-align: center;
    background: #76daff;
    color: #fff;
  }

  .notif-text {
    margin: 25px;
  }

  .notif-text a {
    text-decoration: none;
    color: #333;
    font-size: 0.9em;
  }

  .search-container {
    position: relative;
    width: 100%;
    height: 40px;
    background: #f6f6f6;
    display: flex;
    /*   justify-content: center; */
    align-items: center;
  }

  .search-container .input input {
    width: 121%;
    outline: none;
    border: none;
    background: #fff;
    padding: 5px;
    height: 30x;
    border-radius: 10px;
    font-size: 12px;
    padding-left: 60px;
    margin: 10px
  }

  .search-container .input i {
    position: absolute;
    left: 26px;
    top: 14px;
    color: #bbb;
    font-size: 0.8em;
  }

  .chat-list {
    position: relative;
    height: calc(100% - 170px);
    overflow-y: auto;
  }

  .chat-list .chat-box {
    position: relative;
    width: 100%;
    display: flex;
    /*   justify-content: center; */
    align-items: center;
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #eee;
  }

  .chat-list .chat-box .img-box {
    position: relative;
    width: 55px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
  }

  .chat-list .chat-box .chat-details {
    width: 100%;
    margin-left: 10px;
  }

  .chat-list .chat-box .chat-details .text-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat-list .chat-box .chat-details .text-head h4 {
    font-size: 1.1em;
    font-weight: 600;
    color: #000;
  }

  .chat-list .chat-box .chat-details .text-head .time {
    font-size: 0.8em;
    color: #aaa;
  }

  .chat-list .chat-box .chat-details .text-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-list .chat-box .chat-details .text-message p {
    color: #aaa;
    font-size: 0.9em;
    overlay: hidden;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .chat-list .chat-box .chat-details .text-message b {
    background: #06e744;
    color: #fff;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    /*   text-align: center; */
    font-size: 0.8em;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-list .active {
    background: #ebebeb;
  }

  .chat-list .chat-box:hover {
    background: #f5f5f5;
  }

  .chat-list .chat-box .chat-details .text-head .unread {
    color: #06e744;
  }


  /* right-container */


  .right-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .right-container .header .img-text .user-img .dp {
    position: relative;
    top: -2px;
    left: 0px;
    width: 40px;
    height: auto;
    overflow: hidden;
    object-fit: cover;
  }

  .right-container .header .img-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .right-container .header .img-text h4 {
    font-weight: 500;
    line-height: 1.2em;
    margin-left: 15px;
  }

  .right-container .header .img-text h4 span {
    font-size: 0.8em;
    color: #555;
  }

  .right-container .header .nav-icons {
    position: relative;
    margin-right: 0px;
    /*   padding: 5px; */
  }

  .right-container .header .nav-icons i {
    padding: 10px;
  }

  .chat-container {
    position: relative;
    width: 100%;
    height: calc(100% - 20px);
    /*60+60*/
    padding: 50px;
    overflow-y: auto;
    font-style: italic;
    /* letter-spacing: 1px; */
  }

  .message-box {
    position: relative;
    display: flex;
    width: 100%;
    margin: 5px 0;
    font-style: normal;
  }

  .message-box p {

    right: 0;
    text-align: right;
    max-width: 65%;
    padding: 12px 12px 0 12px;
    /* background: #dcf8c6; */
    border-radius: 10px;
    font-size: 0.9em;
  }

  .message-box.my-message p::before {
    content: '';
    position: absolute;
    max-width: 700px;
    top: 0;
    right: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg, #dcf8c6 0%, #dcf8c6 50%, transparent 50%, transparent);
  }

  .message-box p span {
    /* display: block; */
    margin-top: 5px;
    font-size: 0.8em;
    opacity: 0.5;
  }

  .my-message {
    /* max-width: 700; */
    justify-content: flex-end !important;
  }

  .friend-message p {
    background: #02ca95;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    text-align: left;
    color: #fff;
  }

  .friend-message {
    justify-content: flex-start;

  }
  .friend-message p img {
    display: flex;
    align-items: end;
   width: 50px;

  }

  .chat-container .my-message i {
    color: yellow;
  }

  .message-box.friend-message::before {
    content: '';
    position: absolute;
    top: 27px;
    left: -16px;
    width: 20px;
    height: 20px;
    background: linear-gradient(225deg, #02ca95 0%, #02ca95 50%, transparent 50%, transparent);
  }
  .pointer{
    cursor: pointer;
  }

  .chatbox-input {
    position: relative;
    width: 100%;
    height: 60px;
    background: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chatbox-input i {
    cursor: pointer;
    font-size: 1.8em;
    color: #515855;
  }

  .chatbox-input i:nth-child(1) {
    margin: 15px;
  }

  .chatbox-input i:last-child {
    margin-right: 25px;
  }

  .chatbox-input input {
    position: relative;
    width: 90%;
    margin: 0 20px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1em;
    border: none;
    outline: none;
  }

  .my-message {
    display: flex;
    /* max-width: 700px; */
    justify-content: end;
    align-items: center;
    gap: 10px;

  }

  .phn-svg {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: white;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, .04);
    border-radius: 10px;
    padding: 10px;
    /* max-width: 700px; */
    border: 1px solid #e8ebe9;
    padding: 10px;
  }

  .hidden {
    display: none;
  }

  .chat-container .my-message i {
    font-size: 16px;
    color: black;
    cursor: pointer;
  }
.left-container-heading{
  font-family: Raleway, sans-serif;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1.1px;
    text-align: left;
    color: #868d89;
    text-transform: uppercase;
}
.Datatext{

  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000;
}
.Name-Marco{
  color: #333333;
  font-size: 16px;
  font-weight: 700;
  
}
.css-1tlcqt-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width:100% !important;

}




.phone-image-dummy img{
  width: 40% !important;
  border-radius: 10px;
  margin: 0 auto;
  
 
}

.final-price {
  padding-top: 20px;
  
}

.overall-description-box {
  align-items: unset !important;
}
.message-box-icon i {
  font-size: 16px;
  font-weight: 600;
}
.message-box-icon span {
  font-weight: 600;
  font-size: 14px !important;
opacity: unset !important;
margin-top: unset !important;
}
.price-text {
  font-size: 48px;
  color: #02ca95;
  font-weight: 400;
}



/* animation of sell now button  */
/* ----------------------------------------
 * animation heartbeat
 * ---------------------------------------- */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/* Animation Class */
.heartbeat {
  -webkit-animation: heartbeat 5s ease-in-out infinite both;
  animation: heartbeat 5s ease-in-out infinite both;
}




@media (max-width:1299px) {
  .message-box p  {
    max-width: 80%;
  }
  
}
@media (max-width:1199px) {
  .message-box p  {
    max-width: 100%;
  }
  
}
@media (max-width:992px) {
  .main-container .left-container {
  
  
    padding: 20px;
  }
  .chat-container  {
    padding: 20px;
  }
  
}

@media (max-width: 767px) {
  .Datatext {
    flex-direction: column !important;
   
  }
  .phone-image-dummy {
    display: none;
  }

  .chat-container  {
    padding: 10px;
  }
  .message-box p  {
    max-width: 70%;
  }
  /* .main-container .right-container {
  
    width: 80% !important;
   
  } */
  .main-container .left-container {
  
    flex: 30% 1;
    padding: 10px;
  }
  /* .message-header {
    flex-direction: column;
    align-items: start !important;
  } */
   .message-box-icon {
    flex-direction: column;
    gap: 0 !important;
   }
   .friend-message p img {
    width: 40px;
   }
   .main-container .left-container {
    display: none;
     }
     .formtext {
      font-size: 14px;
     }
     .SellNowButton {
      font-size: 12px;
      padding: 10px 10px;
     }
     .col-4 {
      flex: 0 0 auto;
      width: 100%;
  }
}
@media (max-width: 567px) {
    .message-header {
    flex-direction: column;
    align-items: start !important;
  }
  .message-box-icon {
    flex-direction: unset;
    gap: 10px !important;
   
   }

   .friend-message p img {
    width: 30px;
   }
   .my-message {
    flex-direction: column;
    align-items: unset;
    width: 50% !important;
   
   }
   .message-box {
    justify-self: end !important;

   }
   .message-box {
    font-size: 14px;
   }
   .Searchdata {
    
   }
   .ans-content {
    justify-content: flex-end;
   }

   .phn-svg {
    justify-content: center;
   }
   
}